import {
  Illustrations,
  Icons,
  iconList,
  IncrementSizes,
  Icon,
  ColorsPalette,
} from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { Illustration, IllustrationProps } from '@DesignSystemComponents/Illustration';

// TODO: The '24' has been added manually as we currently can't export tokens from figma.
// TODO: This is why we've had to temporarily do some type coercions (line 23) which will removed later

type Size = '24' | keyof Pick<IncrementSizes, '200' | '100' | '64' | '36' | '28'>;

const sizeToAssetSize: Record<Size, keyof IncrementSizes> = {
  200: '100',
  100: '48',
  64: '36',
  36: '20',
  28: '16',
  24: '24px' as keyof IncrementSizes,
};

export interface PictogramProps extends Pick<ClassNameProps, 'className'> {
  /**
   * The name of the asset.
   */
  name: Illustrations | Icons;

  /**
   * The size of the asset.
   */
  size: Size | Size[];

  /**
   * Color for the rounded background of the asset. Use color token here.
   */
  backgroundColor?: keyof ColorsPalette;

  /**
   * The color of the asset. Use color token here.
   */
  color?: keyof ColorsPalette;
}

/**
 * Component that renders Icon or Illustration within a rounded, colored background
 */
export const Pictogram: React.FC<PictogramProps> = ({
  backgroundColor,
  name,
  size,
  className,
  ...restProps
}) => {
  // Typescript freak https://github.com/Microsoft/TypeScript/issues/26255
  const type = iconList.includes(name as any) ? 'Icon' : 'Illustration';

  const svgSize = Array.isArray(size) ? size.map((s) => sizeToAssetSize[s]) : sizeToAssetSize[size];

  const wrapperStyles: SxStyleProp = {
    display: 'inline-flex',
    backgroundColor,
    textAlign: 'center',
    borderRadius: 'rounded',
  };

  const svgStyles: SxStyleProp = {
    height: svgSize,
    width: svgSize,
  };

  if (type === 'Icon') {
    return (
      <span
        className={className}
        sx={{
          ...wrapperStyles,
          height: size,
          width: size,
          padding: Array.isArray(size)
            ? size.map((s, i) => (Number(s) - Number(svgSize[i])) / 2)
            : (Number(size) - Number(svgSize)) / 2,
        }}
      >
        <Icon
          name={name as Icons}
          size={svgSize}
          {...restProps}
        />
      </span>
    );
  }

  return (
    <Illustration
      className={className}
      name={name as Illustrations}
      size={size as IllustrationProps['size']}
      sx={wrapperStyles}
      illustrationStyles={svgStyles}
      {...restProps}
    />
  );
};
